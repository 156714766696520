body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: white;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

html,
body,
#root {
    height: 100%;
    margin: 0;
}

.fullHeight {
    height: 100%;
}

.flex {
    display: flex;
}

/* this will remove min max button for number type input */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

@media print {
    @page {
        size: A4; /* DIN A4 standard, Europe */
        margin: 5mm 0mm;
    }
}

.MuiTablePagination-caption {
    display: none;
}

.MuiCircularProgress-root {
    z-index: 10000;
}
